#description {
  padding: 100px 0;
  text-align: justify;
  text-justify: inter-word;
}

#description h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#description p {
  line-height: 24px;
}
