#banner {
  padding: 50px;
  background: #6ec1e3;
  color: #fff;
}

#banner .service-desc {
  margin: 10px 10px 20px;
}

#banner h2 {
  color: #fff;
}

#banner i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px #0000000d;
}

#banner h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}

#banner p {
  color: #ffffffbf;
}

