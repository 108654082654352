#footer {
    background: #6ec1e3;
    padding: 30px;
}

#footer p {
  color: white;
  font-size: 14px;
}

#footer a {
  color: white;
}