#infosection {
  padding: 100px 0;
  text-align: justify;
  text-justify: inter-word;
}

.icon {
  font-size: 5em; 
}

.info-container{
  margin-top:20px;
  margin-bottom:40px;
}

@media screen and (width < 700px) {
  .icon {
    margin-bottom:40px!important;
  }
}